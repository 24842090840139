<template>
  <div class="h-min-content xl:w-4/6 sm:w-full px-0">
    <div v-if="loading" class="spinner mx-auto">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div
      class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600"
      v-if="customer && complaint && !loading"
    >
      <div
        class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
        @click="toggleAccordion"
        style="border-bottom: gray solid 1px"
      >
        <p class="text-dark text-left"><b>Detalle de la queja</b></p>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition"
          :class="{ 'transform rotate-180': accordionOpen }"
        >
          <img src="@/assets/icons/arrow-down.svg" alt="" />
        </span>
      </div>
      <div v-if="accordionOpen" class="px-8 py-3">
        <div class="mt-4 w-3/4 mx-auto">
          <ComplaintDetailHeaderCard
            :radicado="complaint.codigo_queja"
            detail="true"
            :status="complaint.estado_cod"
          />
        </div>
        <h2
          v-if="customer && customer.person_type"
          class="my-5 text-xl text-left text-blue"
        >
          <b>Datos de contacto</b>
        </h2>
        <div v-if="errorCustomer" class="mt-5">
          <p class="text-sm text-center text-red-500">{{ feedback }}</p>
        </div>
        <div
          v-if="customer && !errorCustomer"
          class="grid grid-cols-2 gap-4 w-full"
        >
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="customer.person_type"
            :disabled="true"
          />
        </div>
        <CustomerNatural
          v-if="customer && customer.person_type == 1"
          :customer="customer"
        />
        <CustomerLegal
          v-if="customer && customer.person_type == 2"
          :customer="customer"
        />
        <Complaint
          v-if="complaint && Object.keys(complaint).length > 0"
          :complaint="complaint"
        />
        <div class="w-full my-5">
          <BaseButton
            v-if="
              complaint.estado_cod != 'Cerrada' &&
              (complaint.desistimiento_queja == 2 ||
                complaint.desistimiento_queja == null)
            "
            id="desistComplain"
            @click.native="openModal()"
            label="DESISTIR DE QUEJA"
            class="w-full"
            danger="true"
          />
        </div>
        <div class="w-full my-5">
          <BaseButton
            v-if="complaint.estado_cod !== 'Cerrada' && annexesSize < 90"
            @click.native="goAttachments"
            label="ADJUNTAR NUEVOS ANEXOS"
            class="w-full"
            :append="true"
            :disabled="complaint.desistimiento_queja == 1"
          />
        </div>
        <hr />
        <div class="w-full my-8">
          <p
            v-if="complaint.desistimiento_queja == 1"
            class="text-left rounded-xl"
          >
            Ha desistido de la interposición de su queja.
          </p>
          <p
            v-if="
              (complaint.desistimiento_queja == 2 ||
                complaint.desistimiento_queja == null) &&
              complaint.estado_cod == 'Recibida' &&
              !complaint.vencido_por_tiempo
            "
            class="text-left rounded-xl text-gray-600"
          >
          La queja está siendo revisada por la entidad vigilada y le enviará una
          respuesta de manera directa a usted.
          </p>
          <p v-if="complaint.vencido_por_tiempo" class="text-left rounded-xl">
            Han trascurrido dos meses después de recibida la respuesta por parte
            de la entidad. En caso de que persista su inconformidad puede
            radicar una queja nueva.
          </p>
        </div>
        <div
          v-if="
            (complaint.desistimiento_queja == 2 ||
              complaint.desistimiento_queja == null) &&
            !complaint.vencido_por_tiempo
          "
        >
          <div
            v-if="complaint.estado_cod == 'Cerrada'"
            class="w-full flex flex-col gap-4"
          >
            <BaseButton
              v-if="
                complaint.tipo_gestion_actual == 0 &&
                complaint.marcacion_id !== marcation_id
              "
              @click.native="goProcess"
              label="QUIERO TRAMITAR MI QUEJA ANTE EL DEFENSOR DEL CONSUMIDOR FINANCIERO"
              class="w-full"
              invert="true"
              :append="true"
            />
            <BaseButton
              v-if="
                complaint.tipo_gestion_actual == 0 &&
                complaint.marcacion_id !== marcation_id
              "
              @click.native="goReplica"
              label="QUIERO PRESENTAR UNA RÉPLICA"
              class="w-full"
              :append="true"
            />
          </div>
          <!-- <div
            v-if="!complaint.calificado"
            class="w-full mt-5 flex"
          >
            <BaseButton
              @click.native="goCalification"
              id="clasifyService"
              label="CUÉNTENOS SU EXPERIENCIA CON LA PLATAFORMA"
              class="w-full"
              success="true"
              :append="true"
            />
          </div> -->
        </div>
      </div>
    </div>
    <AnnexesManagement 
      :references="general_references"
      :complaint="complaint" 
      v-if="customer && complaint" 
      />
    <AnnexesManagement
      extra="fraude"
      :references="fraude_reference"
      :complaint="complaint"
      v-if="customer && complaint"
    />
    <AnswerManagement :complaint="complaint" v-if="customer && complaint" />
    <acknowledgment-management
      :complaint="complaint"
      :references="acknowledgment_reference"
      v-if="customer && complaint"
      @notify="refreshComplait"
    />
    <ReplicaManagement :complaint="complaint" v-if="customer && complaint" />
    <div class="w-full">
      <AccordionList :items="accordionList" />
    </div>

    <!-- MODAL -->
    <Modal v-if="showModal">
      <div class="w-11/12 mx-auto">
        <h2 class="mb-3 text-2xl text-blue-dark">
          <b>Desisto de la queja presentada</b>
        </h2>
      </div>
      <div class="w-11/12 mx-auto my-5">
        <p class="text-xs text-grey-400"><b>Importante</b></p>
        <p class="text-sm">Señor consumidor financiero:</p>
        <p class="text-sm">
          al confirmar esta acción se dará por finalizado y cerrado el trámite
          de la inconformidad presentada.
        </p>
      </div>
      <div class="w-11/12 mx-auto my-5">
        <a-form-model
          ref="formModel"
          :rules="rules"
          :model="form"
          @submit="desistComplaint"
          @submit.native.prevent
        >
          <a-form-model-item prop="description">
            <BaseTextarea
              :required="true"
              :maxlength="1000"
              label="Descripción"
              rows="3"
              v-model="form.description"
            />
          </a-form-model-item>
          <a-form-model-item prop="files">
            <DragDrop
              label="Anexar archivos de soporte:"
              buttonLabel="SELECCIONE SUS ARCHIVOS"
              id="support_files"
              :fileList="form.files"
              @files-handler="setFiles"
              :required="true"
              maxMB="20"
            />
          </a-form-model-item>
          <a-form-item class="flex justify-center my-5">
            <a-button
              :loading="loading"
              type="primary"
              shape="round"
              html-type="submit"
              class="mr-2"
              >GUARDAR</a-button
            >
            <a-button
              :loading="loading"
              class="ml-2"
              type="danger"
              shape="round"
              ghost
              @click="closeModal"
              >CANCELAR</a-button
            >
          </a-form-item>
        </a-form-model>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Complaint from "@/components/Dashboard/Complaints/Complaint";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";
import ComplaintDetailHeaderCard from "@/components/Dashboard/Complaints/ComplaintDetailHeaderCard";
import AnswerManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnswerManagement";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import ReplicaManagement from "@/components/Dashboard/Complaints/ComplaintManagement/ReplicaManagement";
import { format } from "date-fns";
import AcknowledgmentManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AcknowledgmentManagement";

export default {
  components: {
    AcknowledgmentManagement,
    ComplaintDetailHeaderCard,
    Complaint,
    CustomerLegal,
    CustomerNatural,
    AnswerManagement,
    AnnexesManagement,
    ReplicaManagement,
  },
  async created() {
    await this.getComplaint();
  },
  data() {
    return {
      marcation_id: 7,
      customer: null,
      complaint: null,
      general_references: "!,2,6,7,8,9",  // 2:attorney, 6:management, 7:answer, 8:fraud, 9:acknowledgment
      fraude_reference: "8",
      acknowledgment_reference: "9",
      accordionList: [
        {
          title:
            "¿Cuál es el plazo que tiene la entidad vigilada para responder?",
          body: "La entidad cuenta con 15 días hábiles a la recepción de la queja, para dar respuesta. En caso en que la entidad requiera de un mayor plazo para atender la queja podrá informar al consumidor para obtener un tiempo adicional para responder.",
        },
        {
          title: "¿Qué pasa si el consumidor desiste de la queja?",
          body: "La consecuencia del desistimiento es que la queja será cerrada y recibirá un correo electrónico en el que se informará la modificación del estado de la misma.  Si Usted desiste no podrá continuar con el trámite. Si persisten las inconsistencias después de pasado un tiempo deberá radicar una nueva queja.",
        },
      ],
      showModal: false,
      loading: false,
      complaint_code: null,
      errorCustomer: false,
      feedback: "",
      accordionOpen: false,
      complaint_fraud: { mis_archivos: [] },
      form: {
        description: null,
        files: [],
      },
      rules: {
        description: [
          {
            required: true,
            message: "Este campo es obligatorio",
            trigger: "blur",
          },
          {
            min: 3,
            max: 1000,
            message: "Este campo debe contener entre 3 y 1000 caracteres",
            trigger: "blur",
          },
        ],
        files: [
          {
            required: true,
            message: "Este campo es obligatorio",
            trigger: "blur",
          },
        ],
      },
      acknowledgment_files: [],
    };
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),

    annexesSize: function () {
      let fileSize = 0;
      this.complaint?.mis_archivos?.forEach((file) => {
        fileSize += file.size_file;
      });
      return fileSize;
    },
  },
  methods: {
    async getComplaint() {
      const { complaint_id } = this.$route.query;
      this.complaint_code = complaint_id;

      this.errorCustomer = false;
      this.loading = true;
      let { error, data } = await this.$api.getDetailComplaint(complaint_id);
      this.loading = false;

      if (error)
        this.showToast("error", "Error recuperando información de la queja");

      if (data) {
        const complaint = data;

        complaint.archivos_apoderado = complaint?.mis_archivos?.filter(
          (item) => item.reference === "attorney"
        );

        const numero_id_CF = complaint.numero_id_CF;
        const tipo_id_CF = complaint.tipo_id_CF;
        this.complaint = {
          ...complaint,
          documentacion_rta_final: complaint.documentacion_rta_final
            ? "Sí"
            : "No",
          lgbtiq: complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No": "",
          specialCondition:
            complaint.condicion_especial === "No aplica"?
              2: complaint.condicion_especial == null ? 
              null:
              1,
          abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
          es_apoderado: complaint.apoderado_nombre ? 2 : 1,
          documentacion_final: complaint.documentacion_rta_final ? "Sí" : "No",
          fecha_cierre: complaint.fecha_cierre
            ? format(new Date(complaint.fecha_cierre), "dd/MM/yyyy hh:mm a")
            : "",
          expres: complaint.queja_expres == 1 ? "Sí" : "No",
          tutela_nombre:
          complaint.tutela === 1
            ? "La queja o reclamo tiene asociada una acción de tutela"
            : "La queja o reclamo tiene NO asociada una acción de tutela",
          tipo_gestion_actual: (complaint.historico_gestion
            ? complaint.historico_gestion
            : [{ cod_tipo_gestion: complaint.tipo_gestion_actual }]
          ).filter(
            (item) =>
              item.cod_tipo_gestion !== 6 &&
              item.cod_tipo_gestion !== 7 &&
              item.cod_tipo_gestion !== 8
          ).length,
        };

        this.loading = true;
        let identificationType_id = 1; // Por defecto 1
        const objIdentificationType =
          this.$store.state.form.optionsIdentificationType?.filter(
            (identificationType) => identificationType.name == tipo_id_CF
          );
        if (objIdentificationType.length > 0) {
          identificationType_id = objIdentificationType[0].id;
        }
        // RQ5 - si la queja está cerrada se traen desde Bigquery
        let result = null;
        if (complaint.user_history_id) {
          result = await this.$api.getQueryPersonHistory(
            `?identification_number=${numero_id_CF}&user_history=${complaint.user_history_id}`
          );
        } else {
          result = await this.$api.getQueryPerson(
            `?identification_number=${numero_id_CF}&identification_type=${identificationType_id}`
          );
        }

        this.loading = false;

        if (result.error) {
          this.showToast(
            "error",
            "Error recuperando información del usuario asociado a la queja"
          );
          return;
        }

        if (result.data) {
          if (result.data.results.length === 0) {
            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              person_type: this.complaint.tipo_persona === "Natural" ? 1 : 2,
              first_name: this.complaint.nombres,
              last_name: null,
            };
          } else if (!complaint.user_history_id) {
            const user = result.data.results[0];

            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              birth_date: user.birth_date,
              person_type: user.person_type,
              first_name: user.user_id.first_name,
              last_name: user.user_id.last_name,
              business_name: user.user_id?.business_name,
              phone: user.user_id.phone,
              email: user.user_id.email,
              departamento_cod: user.user_id?.departamento_cod,
              municipio_cod: user.user_id?.municipio_cod,
              address: user.user_id?.address,
              email_notification: user.user_id?.email_notification,
            };
          } else if (complaint.user_history_id) {
            const user = result.data.results[0];
            this.customer = {
              identification_type_name: this.complaint.tipo_id_CF,
              identification_number: this.complaint.numero_id_CF,
              birth_date: user.birth_date,
              person_type: this.complaint.tipo_persona === "Natural" ? 1 : 2,
              first_name: user.first_name,
              last_name: user.last_name,
              business_name: user?.business_name,
              phone: user.phone,
              email: user.email,
              departamento_cod: user?.departamento_cod,
              municipio_cod: user?.municipio_cod,
              address: user?.address,
              email_notification: user?.modified_user_email_notification,
            };
          }
        }
      }
    },
    setFiles(files) {
      this.form.files = files;
    },
    desistComplaint(e) {
      e.preventDefault();
      this.$refs.formModel.validate((valid) => {
        if (valid) {
          let complaintUpdate = {
            desistimiento_queja: 1,
          };
          this.loading = true;
          this.$api
            .desistComplaintAttach(
              this.complaint.codigo_queja,
              {
                ...complaintUpdate,
                argumento: this.form.description,
              },
              this.form.files
            )
            .then(({ data, error }) => {
              if (data) {
                this.showModal = false;
                this.$store.dispatch("complaints/loadComplaints");
                this.$router.push({
                  name: "DashboardMessageDesist",
                  query: {
                    complaintCode: this.$route.query.complaint_id,
                    type: "close",
                  },
                });
              }
              if (error) {
                this.showToast(
                  "error",
                  "Error durante el desestimiento de la queja"
                );
                return false;
              }
            })
            .catch((error) => {
              this.showToast(
                "error",
                "Error durante el desestimiento de la queja"
              );
              return false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    goProcess() {
      this.$router.push(
        `/dashboard/complaint/details/${this.complaint_code}/escalate/${this.complaint.estado_cod}`
      );
    },
    goReplica() {
      this.$router.push(
        `/dashboard/complaint/details/${this.complaint_code}/replica/${this.complaint.estado_cod}`
      );
    },
    goCalification() {
      this.$router.push(
        `/dashboard/complaint/details/${this.complaint_code}/calification`
      );
    },

    goAttachments() {
      this.$router.push(
        `/dashboard/complaint/details/${this.complaint_code}/anexos/${this.complaint.estado_cod}/${this.annexesSize}`
      );
    },
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    async refreshComplait() {
      await this.getComplaint();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>
